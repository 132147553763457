import React from "react";
import { graphql } from "gatsby";

import GridRow from "../components/commons/GridRow";
import Heading from "../components/commons/Heading";
import Page from "../components/commons/Page";
import Box from "../components/commons/Box";
import Header from "../components/commons/Header";
import Image from "../components/commons/Image";
import Button from "../components/commons/Button";
import RichTextRenderer from "../components/commons/RichTextRenderer";
import MarketoForm from "../components/commons/MarketoForm";
import { fadeInAnim } from "../utils/styles";
import useLocalStorage from "../utils/useLocalStorage";
import { LS_FORM_USER_DATA_KEY } from "../utils/constants";

export default function ResourcePage({
  data: { sanityResource, sanityPage, sanitySignupForm },
}) {
  const [persistedData] = useLocalStorage(LS_FORM_USER_DATA_KEY);
  const [isSubmitted, setSubmitted] = React.useState(persistedData);

  const data = sanityResource;

  const handleSuccess = React.useCallback(() => {
    window.location = data.resource[0].asset
      ? data.resource[0].asset.asset.url
      : data.resource[0].url;
  }, [data.resource]);

  const handleReset = React.useCallback(() => {
    setSubmitted(false);
  }, []);

  return (
    <Page
      title={data.seoTitle || data.title || data.heroTitle}
      description={data.seoDescription}
      pathname={`${sanityResource.slug.current}/${data.slug.current}`}
      banner={data.heroImage?.asset.fluid.src}
      sx={{ backgroundColor: "black" }}
    >
      <Header />
      <GridRow sx={{ py: [4, 6] }}>
        <GridRow.Col
          gridColumn={["start / end", "start / span 6", "start / span 6"]}
          sx={{ maxWidth: "100%", color: "inverted" }}
        >
          {data.heroImage && (
            <Image
              width={430}
              fluid={data.heroImage.asset.fluid}
              loading="eager"
              sx={{ borderRadius: "card", width: ["100%", 430] }}
            />
          )}
          <Heading as="h2" sx={{ my: [3, 5] }}>
            {data.title}
          </Heading>
          <RichTextRenderer blocks={data._rawBody} />
        </GridRow.Col>
        <GridRow.Col
          gridColumn={["start / end", "8 / end", "9 / end"]}
          sx={{ mt: [4, 0] }}
        >
          <Box sx={{ position: "relative" }}>
            {isSubmitted && (
              <Box
                sx={{
                  position: [null, "absolute"],
                  top: ["auto", 0],
                  bottom: [null, 3],
                  mb: [4, 0],
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "inverted",
                  ...fadeInAnim,
                }}
              >
                <Heading as="h4" variant="h3" sx={{ mb: 3 }}>
                  Welcome back {JSON.parse(persistedData).FirstName}
                </Heading>
                <span>
                  Not you?{" "}
                  <Button
                    variant="reset"
                    onClick={handleReset}
                    sx={{ verticalAlign: "inherit" }}
                  >
                    Click here
                  </Button>
                </span>
              </Box>
            )}
            <MarketoForm
              onSuccess={handleSuccess}
              submitLabel={sanitySignupForm.submitBtn}
              codeSnippet={data.marketoSnippet}
              sx={{
                transition: (theme) => `opacity ${theme.transitions.normal}`,
                ...(isSubmitted && {
                  ".fields": {
                    opacity: 0.2,
                    pointerEvents: "none",
                    display: ["none", "grid"],
                  },
                }),
              }}
            />
          </Box>
        </GridRow.Col>
      </GridRow>
    </Page>
  );
}

export const pageQuery = graphql`
  query ResourcePage($sanityId: String!) {
    sanityResource(_id: { eq: $sanityId }) {
      title
      seoTitle
      seoDescription
      slug {
        current
      }
      heroImage {
        alt
        asset {
          fluid(maxWidth: 1400) {
            ...GatsbySanityImageFluid
          }
        }
      }
      protected
      marketoSnippet
      resource {
        ... on SanityBaseFile {
          asset {
            asset {
              url
            }
          }
        }
        ... on SanityBaseUrl {
          url
        }
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
    sanityPage(_id: { eq: "contentHub" }) {
      slug {
        current
      }
      blocks {
        ...Blocks
      }
    }
    sanitySignupForm(_id: { eq: "contentHubSignupForm" }) {
      successMessage
      submitBtn
    }
  }
`;
